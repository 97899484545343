<script setup lang="ts">
import { storeToRefs } from 'pinia'

import ListElem from '~/components/shared/agency/favorites/elem.vue'
import VText from '~/components/ui/VText.vue'

import { useBucketSelection } from '~/composables/useBucketSelection'
import { useSegmentBucketList } from '~/composables/useSegmentBucketList'

import { useSnackbarStore } from '~/stores/snackbar'
import { useUserAgencyStore } from '~/stores/userAgency'
import { useUserFavoritesStore } from '~/stores/userFavorites'

import { sortBuckets } from '~/helpers/favorites/bucketsDisplay'
import { trackEvent } from '~/helpers/LegacyTrackEvent'

import type Bucket from '~/types/bucket'
import type { Influencer, StatsV3Influencer } from '~/types/influencer'

type Props = {
  influencers: (Influencer | StatsV3Influencer)[]
}

type ClosePayload = {
  bucket: Bucket
  wasInTheBucket: boolean
  addedInfluencersCount: number
}

type Emits = {
  close: [payload: ClosePayload]
  create: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const route = useRoute()
const { t } = useI18n()
const { trackListSaveFinalized, trackListCreationInitiated } =
  useSegmentBucketList()
const { getClassicBucketName } = useBucketSelection()

const { buckets: BUCKETS } = storeToRefs(useUserFavoritesStore())
const { hidden_influencers: HIDDEN_INFLUENCERS } =
  storeToRefs(useUserAgencyStore())

const { PATCH_BUCKET, ADD_INF_FROM_BUCKET, REMOVE_INF_FROM_BUCKET } =
  useUserFavoritesStore()
const { CREATE: CREATE_SNACKBAR } = useSnackbarStore()

const influencerIds = computed<number[]>(() =>
  props.influencers.map(({ id }) => id),
)
const sortedBuckets = computed<Bucket[]>(() =>
  sortBuckets(
    BUCKETS.value.filter(
      ({ is_groover_made: isGrooverMade }) => !isGrooverMade,
    ),
  ),
)

function close(payload: ClosePayload) {
  emit('close', payload)
}

function addToBucket(bucket: Bucket) {
  const { id: bucketId } = bucket
  const filteredInfluencerIds = [
    ...new Set(
      influencerIds.value.filter(
        (inf) =>
          !bucket.influencers.includes(inf) &&
          !HIDDEN_INFLUENCERS.value.includes(inf),
      ),
    ),
  ]
  ADD_INF_FROM_BUCKET({
    influencerIds: filteredInfluencerIds,
    bucketId,
  })
  // Legacy event - kept for now
  trackEvent(
    {
      category: 'Bucket Management',
      action: 'Add to bucket',
      details: { bucket_id: bucketId },
    },
    route,
  )
}

function removeFromBucket(bucket: Bucket) {
  const { id: bucketId } = bucket

  REMOVE_INF_FROM_BUCKET({
    influencerIds: influencerIds.value,
    bucketId,
  })
  // Legacy event - kept for now
  trackEvent(
    {
      category: 'Bucket Management',
      action: 'Remove from bucket',
      details: { bucket_id: bucketId },
    },
    route,
  )
}

function showErrorSnackbar() {
  CREATE_SNACKBAR({
    props: {
      icon: 'fas fa-triangle-exclamation',
      text: t('error.02'),
    },
  })
}

async function handleBucketUpdate({
  bucketId,
  wasInTheBucket,
}: {
  bucketId: number
  wasInTheBucket: boolean
}) {
  let bucket = BUCKETS.value.find((b) => b.id === bucketId)

  if (!bucket) return
  const previousInfluencersCount = bucket.influencers.length
  wasInTheBucket ? removeFromBucket(bucket) : addToBucket(bucket)

  try {
    await PATCH_BUCKET({ ...bucket })
    bucket = BUCKETS.value.find((b) => b.id === bucketId)

    if (!bucket) return

    const addedInfluencersCount =
      bucket.influencers.length - previousInfluencersCount

    close({
      bucket,
      wasInTheBucket,
      addedInfluencersCount,
    })
    const details = trackListSaveFinalized(
      props.influencers,
      previousInfluencersCount,
      addedInfluencersCount,
      getClassicBucketName(bucket),
    )
    trackEvent(
      {
        category: 'Bucket Management',
        action: 'Save Finalized',
        details,
      },
      route,
    )
  } catch (_) {
    showErrorSnackbar()
  }
}

function showCreate() {
  emit('create')

  trackEvent(
    {
      category: 'Bucket Management',
      action: 'Create a list',
    },
    route,
  )
  trackListCreationInitiated()
}
</script>

<template>
  <div class="tw-relative tw-grid">
    <div
      class="tw-grid tw-max-h-[calc(100vh-84px)] tw-overflow-scroll tw-pb-4 sm:tw-max-h-72"
    >
      <div
        class="elementPadding tw-start-center tw-py-layout tw-flex tw-cursor-pointer tw-items-center tw-gap-4 tw-transition-colors hover:tw-bg-orange-100"
        @click="showCreate"
      >
        <div
          class="tw-flex tw-h-14 tw-w-14 tw-items-center tw-justify-center tw-rounded-full tw-bg-white tw-shadow-round hover:tw-shadow-hover"
        >
          <i class="fas fa-plus" />
        </div>
        <VText cfg="sans/16/medium">{{ $t('band.list01') }}</VText>
      </div>
      <ListElem
        v-for="bucket in sortedBuckets"
        :key="bucket.id"
        :bucket="bucket"
        class="elementPadding"
        :influencer-ids-to-bookmark="influencerIds"
        @bucket-toggled="handleBucketUpdate"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.elementPadding {
  @apply tw-p-4;
}
</style>
