<script setup lang="ts">
import { storeToRefs } from 'pinia'

import AddToBucket from '~/components/agency/favorites/addToBucket.vue'
import BucketCreator from '~/components/agency/favorites/bucketCreator.vue'
import Modal from '~/components/shared/modal.vue'

import { useSegmentTrack } from '~/composables/Segment/useSegmentTrack'
import { useBucketSelection } from '~/composables/useBucketSelection'

import { useMiscResizeStore } from '~/stores/miscResize'
import { useSnackbarStore } from '~/stores/snackbar'
import { useUserFavoritesStore } from '~/stores/userFavorites'

import { trackEvent } from '~/helpers/LegacyTrackEvent'

import { isDefined } from '~/utils'

import { Breakpoints } from '~/enums/breakpoints'

import type Bucket from '~/types/bucket'
import type { Influencer, StatsV3Influencer } from '~/types/influencer'
import type { ListEventDetails } from '~/types/Segment/SegmentEventLibrary/SegmentEventLibraryBucketList'

type Props = {
  influencers: (Influencer | StatsV3Influencer)[]
}

type Emits = {
  close: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const route = useRoute()
const segmentTrack = useSegmentTrack()
const { getClassicBucketName } = useBucketSelection()
const { t } = useI18n()

const showCreate = ref(false)

const { BAND_TRACKING_INFO } = storeToRefs(useUserBandStore())
const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore())
const { buckets: ALL_BUCKETS, ORIGIN_PAGE } = storeToRefs(
  useUserFavoritesStore(),
)

const { PATCH_BUCKET, ADD_INF_FROM_BUCKET, REMOVE_INF_FROM_BUCKET } =
  useUserFavoritesStore()
const { CREATE: CREATE_SNACKBAR } = useSnackbarStore()

const BUCKETS = computed<Bucket[]>(() =>
  ALL_BUCKETS.value.filter(
    ({ is_groover_made: isGrooverMade }) => !isGrooverMade,
  ),
)
const influencerIds = computed<number[]>(() =>
  props.influencers.map((inf: Influencer | StatsV3Influencer) => inf.id),
)

const hiddenCurators = computed<string[]>(
  () =>
    props.influencers
      .map((influencer) => !influencer.visible && influencer.entity)
      .filter((a) => a !== false) as string[],
)

function cancel() {
  trackEvent(
    {
      category: 'Bucket Management',
      action: 'List Creation Cancelled',
    },
    route,
  )
  showCreate.value = false
}

function close({
  bucket,
  wasInTheBucket,
  addedInfluencersCount,
}: Partial<{
  bucket: Bucket
  wasInTheBucket: boolean
  addedInfluencersCount: number
}> = {}): void {
  emit('close')

  if (
    !isDefined(bucket) ||
    !isDefined(wasInTheBucket) ||
    !isDefined(addedInfluencersCount)
  )
    return

  showConfirmationSnackBar(bucket, wasInTheBucket, addedInfluencersCount)
}

function getBucketFromId(bucketId: Bucket['id']): Bucket | undefined {
  return BUCKETS.value.find((b: Bucket) => b.id === bucketId)
}

function onSnackbarLinkClick(
  bucket: Bucket,
  details: ListEventDetails,
  removed: boolean,
): void {
  undoLastAction(bucket.id, influencerIds.value, details, removed)

  trackEvent(
    {
      category: 'Bucket Management',
      action: 'Save to List Snackbar Clicked',
      details,
    },
    route,
  )

  segmentTrack('List - Save to List Snackbar Clicked', details)
}

function showConfirmationSnackBar(
  bucket: Bucket,
  removed: boolean,
  addedInfluencersCount: number,
): void {
  const details = {
    ...BAND_TRACKING_INFO.value,
    list_name: getClassicBucketName(bucket),
    num_curators_saved_to_list: addedInfluencersCount,
    curator_saved_from: ORIGIN_PAGE.value,
  } as ListEventDetails

  const linkObject =
    SCREEN_WIDTH.value < Breakpoints.lg
      ? {
          link: `agency/favorites/${bucket.id}`,
          query: undefined,
        }
      : {
          link: '/#',
          query: { inspectedBucketId: bucket.id },
        }

  createSnackbar(linkObject, removed, addedInfluencersCount, bucket, details)
}

function createSnackbar(
  linkObject: object,
  removed: boolean,
  addedInfluencersCount: number,
  bucket: Bucket,
  details: ListEventDetails,
): void {
  if (hiddenCurators.value.length >= 1) {
    const params = {
      count: Math.abs(addedInfluencersCount),
      bucketName: getClassicBucketName(bucket),
      entity: hiddenCurators.value[0],
      others:
        hiddenCurators.value.length > 1 && hiddenCurators.value.length - 1,
    }
    CREATE_SNACKBAR({
      props: {
        ...linkObject,
        icon: 'fas fa-trash-can',
        text:
          hiddenCurators.value.length > 1
            ? t('agency.favorites.warningMultipleHiddenCurator', params)
            : t('agency.favorites.warningHiddenCurator', params),
        link: '',
      },
    })
  } else {
    CREATE_SNACKBAR({
      props: {
        ...linkObject,
        icon: removed ? 'fas fa-trash-can' : 'fas fa-bookmark',
        text: t(
          removed
            ? 'agency.favorites.confirmationRemovedFromBucket'
            : 'agency.favorites.confirmationAddedToBucket',
          {
            count: Math.abs(addedInfluencersCount),
            bucketName: getClassicBucketName(bucket),
          },
        ),
        linkRight: true,
        linkPlaceholder: t('agency.favorites.undo'),
        onLinkClick: () => onSnackbarLinkClick(bucket, details, removed),
      },
    })
  }
}

function trackLastAction(details: ListEventDetails): void {
  trackEvent(
    {
      category: 'Bucket Management',
      action: 'Removed Curators Re-Added',
      details,
    },
    route,
  )

  segmentTrack('List - Removed Curators Re-Added', details)
}

async function undoLastAction(
  bucketId: Bucket['id'],
  influencerIds: number[],
  details: ListEventDetails,
  removed: boolean,
) {
  const bucket = getBucketFromId(bucketId)

  if (!bucket) return

  details.num_curators_saved_to_list = -details.num_curators_saved_to_list

  if (removed) {
    ADD_INF_FROM_BUCKET({
      influencerIds,
      bucketId,
    })

    trackLastAction(details)
  } else {
    REMOVE_INF_FROM_BUCKET({
      influencerIds,
      bucketId,
    })
  }

  await PATCH_BUCKET({ ...bucket })
}
</script>

<template>
  <Modal
    :title="
      showCreate
        ? $t('agency.favorites.bucketCreator00')
        : $t('agency.favorites.saveToBucket')
    "
    model-value
    :has-cancel="showCreate"
    no-content-padding
    @update:model-value="close()"
    @cancel="showCreate = false"
  >
    <AddToBucket
      v-if="!showCreate"
      key="add-to-bucket"
      :influencers="influencers"
      @create="showCreate = true"
    />
    <BucketCreator
      v-else
      key="bucket-creator"
      :influencers="influencerIds"
      @close="showCreate = false"
    />
  </Modal>
</template>
